<template>
  <div class="text--center">
    <div class="exit__summary">
      <div class="row">
        <div class="col--lg-2 mb--4">
          <div class="text--lg-left">
            <h1 v-html="$t('exit.step2.summary.heading')"></h1>
            <p class="text--small" v-html="$t('exit.step2.summary.subheading')"></p>
          </div>
          <div class="row mb--5" v-if="session?.info?.allowMapGeneration">
            <div class="col--sm-3">
              <div class="box">
                <div class="box__header pb--1">
                  {{ $t('exit.step2.summary.timeSaved') }}
                </div>
                <div class="box__content">
                  <b>{{ exit.timeSaved }}</b>
                </div>
              </div>
            </div>
            <div class="col--sm-3">
              <div class="box">
                <div class="box__header pb--1">
                  {{ $t('exit.step2.summary.distanceSaved') }}
                </div>
                <div class="box__content">
                  <b>{{ exit.distanceSaved }}</b>
                </div>
              </div>
            </div>
            <div class="col--sm-3">
              <div class="box">
                <div class="box__header pb--1">
                  {{ $t('exit.step2.summary.co2Saved') }}
                </div>
                <div class="box__content">
                  <b>{{ exit.co2Saved }}</b>
                </div>
              </div>
            </div>
          </div>
          <exit-share />
        </div>
        <div class="col--lg-2">
          <div class="exit__summary__map">
            <div class="exit__summary__map__pane">
              <img
                v-if="session?.info?.allowMapGeneration && exit.image"
                class="media--fluid"
                :src="exit.image"
                alt
              />
              <div class="exit__summary__map__pane__action">
                <a
                  v-if="session?.info?.allowMapGeneration && exit.image"
                  class="btn btn--outline-primary btn--label mx--1"
                  :href="exit.image"
                  download="aire-link-summary-map"
                >
                  <a-icon name="save" class="me--2" />
                  <span class="ms--2">{{ $t('exit.step2.saveMap') }}</span>
                </a>
                <a
                  v-if="session?.info?.allowMapGeneration && exit.link"
                  class="btn btn--outline-primary btn--label mx--1"
                  :href="exit.link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a-icon name="marker" class="me--2" />
                  <span class="ms--2">{{ $t('exit.step2.openMap') }}</span>
                </a>
              </div>
            </div>
            <p v-if="session?.info?.allowMapGeneration && exit.image && exit.inacurrate">
              {{ $t('exit.step2.locationsInaccurate') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  useExitStore, useSessionStore, useUserStore
} from '@/stores'

const exit = useExitStore()
const session = useSessionStore()

</script>

<style lang="scss">
</style>
