<template>
  <a-layout class="screensharing">
    <video
      ref="screensharing"
      class="media media--contain"
      autoplay
      muted
      playsinline
    ></video>
    <div v-if="user.isAssistant" class="screensharing__overlay"></div>
  </a-layout>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useScreensharingStream } from '@/composables'
import { useUserStore, useSessionStore, useTransformStore } from '@/stores'

const user = useUserStore()
const session = useSessionStore()
const transform = useTransformStore()

const { video: screensharingVideo } = useScreensharingStream()
const screensharing = ref(null)

const { zoom, x: tx, y: ty } = storeToRefs(transform)

const width = computed(() => props.hidden ? '0' : `${session.transform.client.width * session.transform.scale}px`)
const height = computed(() => props.hidden ? '0' : `${session.transform.client.height * session.transform.scale}px`)

const x = computed(() => tx.value * session.transform.scale)
const y = computed(() => ty.value * session.transform.scale)

watchEffect(() => {
  if (screensharing?.value) screensharing.value.srcObject = screensharingVideo.value
})
</script>

<style lang="scss">
$screensharing-bg: $black;

.screensharing {
  background-color: $screensharing-bg;
  z-index: $zindex-video;
  touch-action: none;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $backdrop;
  }
  video {
    transition-property: transform;
    transition-duration: $duration-fast;
    transform: matrix(v-bind(zoom), 0, 0, (v-bind(zoom), v-bind(x), v-bind(y)));
  }
}
</style>
