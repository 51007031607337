import axios from 'axios'
import messenger from "./Messenger"

import { con, repository } from './messages/index.js'
import { logger } from '../../logger/src/index.js'
import { 
  SessionNotFoundError,
  SessionNotStartedError,
  SessionExpiredError,
  PartyHasAlreadyJoinedError,
  UserNotAuthorizedError,
  UserDisjoinError,
  UserNotFoundError
} from './errors/index.js'

export default class Session {
  constructor (mediator) {
    this.mediator = mediator
    this.participants = []
    this.user = null
    this.callId = null
    this.sessionInfo = null
    this.userInfo = null
    this.started = false
    this.strategy = []
    this.recorderReference = null
  }

  close () {}

  getApiSessionInfo (endpoint, appType, key, callId) {
    endpoint = endpoint.replace('{{callId}}', callId).replace('{{key}}', key)
    this.callId = callId
    return new Promise(async (resolve, reject) => {
      
      axios.get(endpoint)
        .then(({ data: response }) => {
          if (response && !response.sessionValid) {
            switch (response.errorCode) {
              case 'sessionNotStarted': {
                logger('communication', 'error', 'plt:info - session is not valid (not_started)', response)
                reject(new SessionNotStartedError(response.error, response))
                break
              }
              case 'sessionNotFound': {
                logger('communication', 'error', 'plt:info - session is not valid (not_found)', response)
                reject(new SessionNotFoundError(response.error, error))
                break
              }
              case 'sessionExpired': {
                logger('communication', 'error', 'plt:info - session is not valid (expired)', response)
                reject(new SessionExpiredError(response.error, response))
                break
              }
            }
          }
          if (!response.user) {
            reject(new UserNotFoundError('User not found', response))
            logger('communication', 'error', 'plt:info - user not found', response)
            return
          }

          const { user } = response
          if (!this.authorizeUser(appType, user)) {
            logger('communication', 'error', 'plt:info - user is not authorized to join', { appType, user })
            reject(new UserNotAuthorizedError(`User is not authorized to join ${appType} app`))
            return
          }
          this.sessionInfo = response
          this.user = { ...user, key }
          this.user.id = this.user.id.toString()
          this.strategy = this.getStrategy()
          resolve(response)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 404: {
              logger('communication', 'error', 'plt:info - session is not valid (not_found)', error)
              reject(new SessionNotFoundError(error.statusText, error))
              break
            }
          }
        })
    })
  }

  authorizeUser (appId, user) {
    const { party } = user
    return ((party === 'client' && appId === 'client') || ((party === 'observer' || party === 'assistant') && appId === 'assistant'))
  }

  join () {
    return new Promise((resolve, reject) => {
      const { user: { id, key, party }, callId } = this
      this.mediator.send(con.joinMessage(id, key, party, callId), (error, response) => {
        if (error) {
          const { message } = error 
          if (message === 'PARTY_HAS_ALREADY_JOINED') {
            logger('communication', 'error', 'con:join - user is already joined', error)
            reject(new PartyHasAlreadyJoinedError('User is already in session', error))
            return
          }
          logger('communication', 'error', 'con:join', error)
          reject(error)
        }
        messenger.subscribe('communication:session:participantJoined', 'websocket:participant:joined', this.participantJoinedSessionHandler)
        messenger.subscribe('communication:session:participantDisjoined', 'websocket:participant:disjoined', this.participantDisjoinedSessionHandler)
        this.configuration = response
        logger('communication', 'info', 'con:join - user joined', this.user)
        this.getJoin()
        this.getChat()
        this.getTransform()
        resolve({
          user: this.user,
          configuration: this.configuration,
        })
      })
    })
  }

  disjoin () {
    return new Promise((resolve, reject) => {
      this.mediator.send(con.disjoinMessage(), (error, response) => {
        if (error) {
          logger('communication', 'error', 'con:disjoin', error)
          reject(new UserDisjoinError())
          return
        }
        logger('communication', 'info', 'user succesfully disjoined')
        messenger.emit('session:user:disjoined')
        resolve(response)
      })
    })
  }

  getJoin () {
    this.mediator.send(repository.fetchEventsMessage('join'), (error, response) => {
      if (error) {
        return
      }
      
      const messages = JSON.parse(response.value)
      if (messages.length > 0 && messages[0].timestamp) {
        const date = new Date(messages[0].timestamp)
        messenger.emit('session:time', { timestamp: date.getTime() })
      }
    })
  }


  getStrategy () {
    const client = (participant) => participant.party === 'client'
    const assistant = (participant) => participant.party === 'assistant'
    const hasClient = !!this.sessionInfo.participants.find(client)
    const hasAssistant = !!this.sessionInfo.participants.find(assistant)
    const strategy = hasClient && hasAssistant && this.sessionInfo.participants.length === 2 ? 'p2p' : 'mcu'
    logger('communication', 'info', `selected strategy is ${strategy}`, {
      hasClient,
      hasAssistant,
      numberOfParticipants: this.sessionInfo.participants.length
    })
    return strategy
  
  }

  getChat () {
    this.mediator.send(repository.fetchEventsMessage('chat'), (error, response) => {
      if (error) {
        return
      }
      messenger.emit('chat:history', { history: response })
    })
  }

  getTransform () {
    this.mediator.send(repository.fetchEventsMessage('transform'), (error, response) => {
      if (error) {
        return
      }
      messenger.emit('transform:history', { history: response })
    })
  }

  canStart () {
    const partyJoined = (party) => !!this.participants.find((participant) => participant.party === party) || this.user.party === party
    if (partyJoined('client') && partyJoined('assistant')) {
      return true
    }
    return false
  }

  participantJoinedSessionHandler = (event) => {
    const { id: participantId } = event
    const participant = this.sessionInfo.participants.find((participant => Number(participant.id) === Number(participantId)))
    this.participants.push(participant)
    logger('communication', 'info', 'con:joined - participant joined', participant)
    messenger.emit('session:participant:joined', { participant: Object.assign({}, participant) })
    if (!this.started && this.canStart()) {
      this.started = true
      logger('communication', 'info', 'session start', participant)
      messenger.emit('session:start')
    }
  }

  participantDisjoinedSessionHandler = (event) => {
    const { id: participantId } = event
    const participant = this.sessionInfo.participants.find((participant => Number(participant.id) === Number(participantId)))
    this.participants = this.participants.filter((participant) => Number(participant.id) !== Number(participantId))
    logger('communication', 'info', 'con:disjoined - participant disjoined', participant)
    messenger.emit('session:participant:disjoined', Object.assign({}, participant))
  }

  setRecorderReference (reference) {
    this.recorderReference = reference
  }

  clearRecorderReference () {
    this.recorderReference = null
  }

  getReference = () => this.recorderReference || this.configuration.reference
}
