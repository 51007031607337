import container from '@di'
import { snapshot } from '@/messages'
import { 
  useLocalStream,
  useBlob,
  useS3Service,
  useRepository,
  useSound
} from '@/composables'
import { 
  useAppStore,
  useUserStore,
  useSnapshotStore
} from '@/stores'
import { sleep } from '@/utils'
import { useTranslation } from 'i18next-vue'

export default class SnapshotProcedure {
  constructor () {
    const { t } = useTranslation()
    const { playSound } = useSound(['camera'])
    this.name = 'snapshotProcedure'
    this.listener = null
    this.app = useAppStore()
    this.user = useUserStore()
    this.store = useSnapshotStore()
    this.t = t
    this.playSound = playSound
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    container.messenger.subscribe(this.name, 'strategy:data:progress', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = async (event, channel) => {
    if (channel === 'strategy:data') {
      const { type, data } = event
      switch (type) {
        case 'snapshot:take': {
          if (this.user.isClient) {
            this.playSound('camera')
            this.app.loading(this.t('loader.takingSnapshot'))
            try {
              const { getSnapshot } = useLocalStream()
              const { blobToBase64 } = useBlob()
              const { data } = await getSnapshot()
              const image = data instanceof window.Blob ? await blobToBase64(data) : data
              this.send(snapshot.dataMessage(image))
              this.listener(image)
              this.app.transition('draw')
            } catch (error) {
              console.error(`[snapshot:take] ${error}`)
            } finally {
              this.app.loaded()
            }
          }
          break
        }
        case 'snapshot:dismiss': {
          this.app.transition('LEAVE_DRAW')
          break
        }
        case 'snapshot:data': {
          this.listener(data)
          this.app.transition('draw')
          this.app.loading(this.t('loader.transferringSnapshot', {
            progress: 100
          }))
          await sleep(100)
          this.app.loaded()
          if (!this.user.isAssistant) {
            this.playSound('camera')
          }
          break
        }
      }
    } else if (channel === 'strategy:data:progress') {
      const { type, progress } = event
      if (type === 'snapshot:data') {
        this.app.loading(this.t('loader.transferringSnapshot', {
          progress: Math.round(progress * 100)
        }))
      }
    }
  }

  take = () => {
    const { sendWsEvent } = useRepository()
    this.playSound('camera')
    this.app.loading(this.t('loader.takingSnapshot'))
    this.send(snapshot.takeMessage())
    sendWsEvent(snapshot.takeMessage())
  }

  dismiss = async (type) => {
    const { sendWsEvent } = useRepository()
    this.app.loading(type === 'snapshot' ? this.t('loader.savingSnapshot') : this.t('loader.savingUploadedImage'))
    this.send(snapshot.dismissMessage())
    sendWsEvent(snapshot.dismissMessage())
    this.app.transition('LEAVE_DRAW')
    await sleep(1000)
    this.app.loaded()
  }

  drop = (data) => {
    this.send(snapshot.dataMessage(data))
    this.listener(data)
    this.app.transition('draw')
    this.app.loaded()
    this.save(data, true)
  }

  send = (message) => {
    const { agent } = container
    agent.broadcast(message)
  }

  save = async (data, board = false) => {
    try {
      const { saveSnapshot } = useS3Service()
      const { sendWsEvent } = useRepository()
      const { key, bucket } = await saveSnapshot(data)
      this.store.setKey(key)
      if (board) {
        sendWsEvent(snapshot.boardMessage({ key, bucket }))
      } else {
        sendWsEvent(snapshot.saveMessage({ key, bucket }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  addListener = (listener) => {
    this.listener = listener
  }
}
