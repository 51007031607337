<template>
  <a-modal :title="`Access to your ${context} is blocked`" @close="app.transition('HIDE_PERMISSIONS_MODAL')">
    <p>#todo To allow access to your {{context}} click on the lock icon and turn on {{context}}.</p>
    <img class="w--100 tutorial" :src='`/tutorials/${imgTutorial}.gif`' alt="" />   
    <template #footer>
      <a-button
        @click="app.transition('HIDE_PERMISSIONS_MODAL')"
      >
        OK
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { computed } from 'vue'
import { useAppStore } from '@/stores'
import { useAppMachine } from '@/state'

const app = useAppStore()
const { state } = useAppMachine()
const context = computed(() => state.value.context.permissions)

const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
const platform = navigator.userAgent.toLowerCase();
let deviceType = 'PC';
let browser = 'Chrome';
let imgTutorial = context;

switch (true) {
  case /android/i.test(platform):
    deviceType = 'Android';
    break;
  case /iphone|ipad|ipod/i.test(platform):
    deviceType = 'iOS';
    break;
}

switch (true) {
  case platform.includes('edg'):
    browser = 'Edge';
    break;
  case platform.includes('firefox'):
    browser = 'Firefox';
    break;
  case platform.includes('safari') && !platform.includes('chrome') && !platform.includes('edg'):
    browser = 'Safari';
    break;
  case platform.includes('chrome'):
    browser = 'Chrome';
    break;
}

if (isMobile) {
  switch (deviceType) {
    case 'iOS':
      imgTutorial = (browser === 'Chrome') ? 'chrome-ios' : 'safari';
      break;
    case 'Android':
      switch (browser) {
        case 'Chrome':
          imgTutorial = 'chrome-android';
          break;
        case 'Firefox':
          imgTutorial = 'firefox';
          break;
        default:
          imgTutorial = 'edge';
          break;
      }
      break;
  }
}

</script>
<style lang="scss">
  .tutorial{
    height: 500px;
    object-fit: contain;
  }
</style>
